body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Multicolore Pro", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #25262e;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.snackbar-success {
  background-color: #1dacd6 !important;
  border-radius: 10px !important;
}

.snackbar-error {
  background-color: #fe5e55 !important;
  border-radius: 10px !important;
}

#root {
  height: 100%;
  width: 100%;
}

/* workaround for visually adapting the Material UI date input dialogs to match our application */

.MuiPickersBasePicker-container {
  padding: 10px !important;
}

.MuiPickersPopover-root > .MuiPaper-root {
  border-radius: 20px;
  box-shadow: 2px 0px 30px #13245240;
}
